import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Progress.module.scss"

const cardData = [
  {
    heading: "I'm a",
    subHeading: "Startup.",
    description: "Don't let execution delays or talent gaps slow your growth.",
    textColor: "black",
  },
  {
    heading: "I'm a",
    subHeading: "Enterprise.",
    description: "Get Scalable Enterprise Solutions for your Business.",
    textColor: "white",
  },
  {
    heading: "I'm a",
    subHeading: "Rescue.",
    description: "A messy codebase and Mismanaged tech debt.",
    textColor: "black",
  },
]

const Progress = () => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}>
            Backing Your Up At <span>Every Stage of your Progress</span>
          </h2>
          <p className={styles.description}>
            From launching startups to expanding enterprises, or recovering from
            setbacks, we help businesses overcome every challenge on their
            journey.
          </p>
          <Row className="gap-30">
            {cardData.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={styles.colGap}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p className={styles.heading}>{item.heading}</p>
                  <h3 className={styles.subHeading}>{item.subHeading}</h3>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.description}
                  </p>
                  <Link className={`newBtn ${styles[item.textColor]}`}>
                    See All Services
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <h2>Got An Idea Or Concerns? Let’s Discuss.</h2>

            <Link to={"/contact-us/"} className="newBtn" style={{color: "white"}}>
              Talk To An Ace
            </Link>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Progress
