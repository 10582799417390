import React, { Suspense, lazy, useEffect, useState } from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/home-sections/Banner"
import "../components/home-sections/home.scss"
import MainLayout3 from "../layouts/MainLayout3"
import Stories from "../components/home-sections/Stories"
// import Concerns from "../components/home-sections/Concerns"
import Clients from "../components/home-sections/Clients"
import Techstack from "../components/home-sections/Techstack"
import TechstackMobile from "../components/home-sections/TechstackMobile"
import ClientLogos from "../components/home-sections/ClientLogos"
import CaseStudies from "../components/home-sections/CaseStudies"
import Models from "../components/home-sections/Models"
import ModelsMobile from "../components/home-sections/ModelsMobile"
import Progress from "../components/home-sections/Progress"
import Success from "../components/home-sections/Success"

const HomePage = ({ data }) => {
  console.log(data)

  const [state, setState] = useState(false)

  const isSSR = typeof window === "undefined"

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        setState(true)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <MainLayout3>
      <Banner />
      <ClientLogos />
      <Stories />
      <Progress/>
      {/* <Concerns /> */}
      <Success/>
      {!isMobile ? <Models /> : <ModelsMobile />}
      <Clients />
      <CaseStudies />
      {!isMobile && !isTablet ? <Techstack /> : <TechstackMobile />}
      <ContactSales />
    </MainLayout3>
  )
}

export default HomePage

export const Head = () => (
  <SEORevamp
    title="InvoZone | Software Development and Consulting Company"
    description="At InvoZone, we provide software development & consultation services to startups & SMEs. We help tech companies scale their engineering capacity. Contact today!"
    image="https://invozone-backend.s3.amazonaws.com/invozone_og_3df3c5e89a.webp"
  />
)
