import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = () => {
  return (
    <div className={styles.section}>
      {/* <div className={styles.bannerMobileHome}></div> */}
      <div className={styles.bannerDeskHome}>
        <div className="bannerVideo">
          <div>
            <video
              autoPlay
              muted
              loop
              poster="https://invozone-backend.s3.us-east-1.amazonaws.com/hero_section_ffbce6b7ca.webp"
            >
              <source
              // src="https://invozone-backend.s3.amazonaws.com/Hero_Section_Loop_Video_Final_Format_b5a1af278b.mp4"
              // type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <p className={styles.subHeading}>
                Software Development Consulting
              </p>
              <h1 className={styles.heading}>Future-Driven Innovations.</h1>
              <p className={styles.dec}>
                Fostering growth for Startups, Enterprises, and Innovators.
              </p>
              <p className={styles.claedly}>
                <img
                  style={{ marginRight: "10px" }}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007249_2dbf971c3a.png"
                />
                Book a 15 min Call
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default Banner
