// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--dd7aa";
export var Trust = "Success-module--Trust--4a5e0";
export var clientContent = "Success-module--clientContent--9988e";
export var dec = "Success-module--dec--5fbc9";
export var designation = "Success-module--designation--5f0d2";
export var heading = "Success-module--heading--2195c";
export var iconContainer = "Success-module--iconContainer--ae19a";
export var iconContainerLeft = "Success-module--iconContainerLeft--c002b";
export var logos = "Success-module--logos--16128";
export var logosBg = "Success-module--logosBg--ed58d";
export var name = "Success-module--name--1d360";
export var playBtn = "Success-module--playBtn--3050f";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--50422";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--4c4c6";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--8204a";
export var view = "Success-module--view--398c6";