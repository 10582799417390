import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Stories.module.scss"
import { Link } from "gatsby"

export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Product Engineering",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/settings_3_1_51a882e5f7.svg",
      description:
        "Our Product Engineering Services cover product management, design, development, DevOps, testing, security & more.",
      button: "/contact-us/",
      skills: [
        "Discovery workshop",
        "Custom software development",
        "Web app development",
        "Mobile app development",
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_2_1_8b9b9d2def.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/product_engineer_icon_3_69e6bdb724.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ror_d85f47fbb1.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_59fd5e77fc.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_28e1909228.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_46d506232e.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_9148189919.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_8f34728bad.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_f8fa1da08e.svg",
        },
      ],
    },
    {
      name: "Data & Artificial Intelligence",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_d7ddbab7d6.svg",
      description:
        "Keeping ourselves in the loop with the latest AI trends, we help businesses use data & AI to make informed decisions.",
      button: "/ai-development-services/",
      skills: [
        "AI strategy and consulting",
        "AI model deployment and integration",
        "Natural language processing",
        "Machine learning model development",
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ai4_4565ebbd11.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_1_2c73b93f69.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/kareas_ec37659b12.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensor_flow_6b81d59d9c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ai5_acc86a0c87.svg",
        },
      ],
    },
    {
      name: "Hire Dev",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/chat_3_1_3472292811.svg",
      description:
        "Invozone helps you hire, acquire and retain top development talent. Boost your in-house team or build your own dev center.",
      button: "/contact-us/",
      skills: [
        "Staff Augmentation",
        "Dedicated Teams",
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/sass_c0619efad0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_536eb91e2a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/spacy_2839823b84.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/sklearn_09b029aaf8.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_27fdeb4058.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/nodejs_807e58800c.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/webflow_7ca6b1472e.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_242dd7c402.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_33fe4a74c8.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/hire_dev_1124d743d6.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/gcp_c1913deaef.svg",
        },
      ],
    },
    {
      name: "DevOps & CloudOps",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/cloud_computing_5_1_b0cc059ea8.svg",
      description:
        "We optimize operations with advanced DevOps and CloudOps strategies while boosting agility & scalability in technology deployments.",
      button: "/devops/",
      skills: [
        "CI/CD as a service",
        "Infrastructure as code",
        "Security and Compliance",
        "Monitoring and logging",
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_0f9977de98.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/ibm_cloud_91d9924949.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_cd6d84b8f0.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/devops_icon2_4e7a1bb953.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_c405d993c7.svg",
        },
      ],
    },
    {
      name: "Emerging Technologies",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007569_277470ef3c.svg",
      description:
        "We’ve plunged ourselves into the emerging IT trends, therefore, you can trust us with anything that’s new in the market.",
      button: "/contact-us/",
      skills: ["Metaverse", "AR/VR", "Apple vision pro", "Cyber security"],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/unreal_316c0a1e1a.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/unity_381bcd5e96.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_ebd805e804.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/emerging_tech_2_0a1607af00.svg",
        },
      ],
    },
    {
      name: "Blockchain & Web 3.0",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/blockchain_2_1_37e24ad7c8.svg",
      description:
        "Building on blockchain technology, we develop secure, decentralized applications that redefine digital trust & efficiency.",
      button: "/contact-us/",
      skills: [
        "DApp Development",
        "Smart Contract Deployment",
        "Blockchain Consulting",
        "Tokenization Solutions",
      ],
      productImages: [
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/pngwing_a4a28009a6.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_f14488392f.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_f862dfb9c2.svg",
        },
        {
          img: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_16d2df1641.svg",
        },
      ],
    },
  ]

  const [activeStory, setActiveStory] = useState(data[0])

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={styles.section}>
        <Container>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>
              Software Development Services <span>Forward Planners</span>
            </h2>
            <div className={styles.talkBtn}>
              <Link to={"/portfolio/"} className="newBtn">
                View All
                {/* <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_Stroke_123158fa91.png"
                  decoding="async"
                  loading="lazy"
                /> */}
              </Link>
            </div>
          </div>
          <Row className="gap-50">
            <Col xs={12} lg={5} className={styles.storyCardWrapper}>
              <Row className="gap-19 justify-content-center">
                {data?.map(el => (
                  <Col
                    xl={4}
                    lg={6}
                    md={6}
                    className={`${styles.rowMarging} gap-30`}
                    key={`story_${el.name}`}
                  >
                    <div
                      className={`${styles.storyCard} ${
                        el.name === activeStory.name && styles.active
                      }`}
                      onClick={() => setActiveStory(el)}
                    >
                      <div
                        className={`${styles.imgContainer} d-flex justify-content-center align-items-center`}
                      >
                        <img
                          src={el?.avatar}
                          alt={el?.name}
                          decoding="async"
                          loading="lazy"
                          className={`${styles.serImg} img-fluid`}
                        />
                      </div>
                      <div className={`${styles.infoContainer} text-center`}>
                        <p>{el?.name}</p>
                        <p className={styles.role}>{el?.role}</p>
                      </div>
                    </div>
                    {el.name === activeStory.name && (
                      <div
                        className={`${styles.columnTopBannerImg}  d-sm-block d-md-none`}
                      >
                        {data?.map(el => (
                          <div>
                            {el.name === activeStory.name && (
                              <div className={styles.subHeading}>
                                <p className={styles.plannerSub}>{el?.name}</p>
                                <p className={styles.dec}>{el?.description}</p>
                                <div className={styles.points}>
                                  {el.skills &&
                                    el.skills.map((skill, eIdx) => (
                                      <div className={styles.arrowPoint}>
                                        <img
                                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_c1a0254ee7.svg"
                                          decoding="async"
                                          loading="lazy"
                                        />
                                        <p className={`${styles.skill}`}>
                                          {skill}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                                <div >
                                {el.productImages &&
                                  el?.productImages.map((prodImg, ind) => (
                                   
                                      <img
                                        src={prodImg?.img}
                                        className={styles.prodImg}
                                        decoding="async"
                                        loading="lazy"
                                        style={{
                                          height: "31px",
                                          width: "31px",
                                        }}
                                      />
                                    ))}
                                    </div>
                                <Link to={el?.button} className="newBtn mt-3" >
                                  See All Services
                                </Link>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }}>
              <div
                className={`${styles.columnTopBannerImg2} d-md-block d-sm-none `}
              >
                {data?.map(el => (
                  <div>
                    {el.name === activeStory.name && (
                      <div className={styles.subHeading}>
                        <h3 className={styles.subHeading2}>{el?.name}</h3>
                        <p className={styles.dec}>{el?.description}</p>
                        <div className={styles.points}>
                          {el.skills &&
                            el.skills.map((skill, eIdx) => (
                              <div className={styles.arrowPoint}>
                                <img
                                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_c1a0254ee7.svg"
                                  decoding="async"
                                  loading="lazy"
                                />
                                <p className={`${styles.skill}`}>{skill}</p>
                              </div>
                            ))}
                        </div>
                        {el.productImages &&
                          el?.productImages.map((prodImg, ind) => (
                            <div className={styles.prodImg}>
                              <img
                                src={prodImg?.img}
                                decoding="async"
                                loading="lazy"
                                style={{ height: "31px", width: "31px" }}
                              />
                            </div>
                          ))}
                        <div style={{ marginTop: '24px' }}>
                          <Link to={el?.button} className="newBtn mt-5" >
                            See All Services
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
