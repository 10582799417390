// extracted by mini-css-extract-plugin
export var active = "Stories-module--active--91fcf";
export var arrowPoint = "Stories-module--arrowPoint--9afed";
export var columnTopBannerImg = "Stories-module--columnTopBannerImg--b9af7";
export var columnTopBannerImg2 = "Stories-module--columnTopBannerImg2--88921";
export var dec = "Stories-module--dec--1d81d";
export var headSearch = "Stories-module--headSearch--07ad8";
export var heading = "Stories-module--heading--e9a8f";
export var imgContainer = "Stories-module--imgContainer--538e7";
export var infoContainer = "Stories-module--infoContainer--c654c";
export var plannerSub = "Stories-module--plannerSub--540dd";
export var points = "Stories-module--points--bdc78";
export var prodImg = "Stories-module--prodImg--03f58";
export var role = "Stories-module--role--169a1";
export var rowMarging = "Stories-module--rowMarging--8895a";
export var section = "Stories-module--section--8d254";
export var serImg = "Stories-module--serImg--e922b";
export var storyCard = "Stories-module--storyCard--e8b73";
export var storyCardWrapper = "Stories-module--storyCardWrapper--12a50";
export var subHeading = "Stories-module--subHeading--d579f";
export var subHeading2 = "Stories-module--subHeading2--17e7d";