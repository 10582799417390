import React, { useState } from "react"
import * as styles from "./Success.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import { Link } from "gatsby"
import ReactPlayer from "react-player"
// import "./Clients.scss"
import LeftArrow from "../../images/softwere-development/left.svg"
import RightArrow from "../../images/softwere-development/right.svg"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={RightArrow}
          onClick={onClick}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={LeftArrow}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const TrustInvoZone = ({ strapiData, projectEngRTest }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState("")

  const data = [
    {
      name: "David Smith",
      designation: "CEO & Co-Founder - Easyfill",
      dec: "InvoZone has been a game-changer for us, offering fresh ideas and genuine support. This wasn’t our first outsourcing experience, but it has definitely been the smoothest.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19829_81dcf601de.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/Easyfill_27b51f4ed8.png",
      video: "https://www.youtube.com/watch?v=aOw4IfvEng4",
    },
    {
      name: "Oliver Wolff",
      designation: "Kindle Product Manager",
      dec: "We partnered with InvoZone to enhance our Elixir SDK, and the process was seamless. Consistent communication and timely status updates, even with minor estimate shifts, allowed us to adapt efficiently. Their proactive approach kept us informed, and the code quality exceeded our expectations, reducing extensive testing. Overall, InvoZone's clear communication and high-quality delivery made them a standout partner, ensuring smooth collaboration throughout the project.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/oliver_wolff_f793e79dc8.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/kinde_ca9fa25ebc.png",
    },
    {
      name: "Ron Zabel",
      designation: "CEO - Crytool.io",
      dec: "InvoZone has been a game-changer for us, bringing fresh ideas and genuine support to our projects. This was not the first time we outsourced, but for sure the smoothest experience we had.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ron_zabel_dfecf54574.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/crypool_56fc476050.png",
    },
    {
      name: "Ryan Carter",
      designation: "Co Founder - Shield Republic",
      dec: "We were initially hesitant to outsource overseas, especially after our previous experience with another company that provided average services. Since partnering with InvoZone, we've seen no decline in quality. The team is responsive, easy to work with, and possesses comprehensive knowledge of web app development and API integrations.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ryan_98b623518c.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/shield_republic_c0730815cc.png",
    },
    {
      name: "Chris Dominguez",
      designation: "CEO - StorageChain",
      dec: "Every member of the InvoZone team has been diligent and focused, fostering a genuine partnership on the technology front.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/chris_domingue_b358d372fa.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/stroage_chain_594f546245.png",
    },
  ]

  const logoData = [
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_2_64c502a23f.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/4j_LL_68_1cf139b098.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1720e8c973.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1_d29ba30c66.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Services_Categories_e5e200215f.png",
    },
  ]

  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    // speed: 500,
    centerMode: true,
    centerPadding: "0px",
    // className : 'center',
    // dots: true,
    // dotsClass: "clients-dots",
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
        },
      },
    ],
  }

  const handleVideoPlay = videoUrl => {
    setCurrentVideo(videoUrl)
    setShowModal(true)
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2 className={styles.heading}>
            Partner’s <span>Success</span> Stories{" "}
          </h2>

          <Slider {...settings} className={styles.SliderWidth}>
            {data?.map((el, i) => (
              <div className={styles.clientContent} key={i}>
                <Row className="align-items-center justify-content-center gap-30">
                  <Col lg={6}>
                    <div>
                      <img src={el?.image2} alt={el?.name} />
                      <button
                        className={styles.playBtn}
                        onClick={() => handleVideoPlay(el.video)}
                      >
                        Play Video
                      </button>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div>
                      <img src={el?.logo} />
                      <h3 className={styles.name}>{el?.name}</h3>
                      <p className={styles.designation}>{el?.designation}</p>
                      <p className={styles.dec}>{el?.dec}</p>
                      <p className={styles.view}>View Case Study</p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </Slider>

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Video Player</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactPlayer
                url={currentVideo}
                width="100%"
                height="400px"
                controls
              />
            </Modal.Body>
          </Modal>
        </Container>
        <div className={styles.logos}>
          <Container>
            <div className={styles.logosBg}>
              {logoData?.map((e, i) => (
                <img src={e?.img1} />
              ))}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
